import Layout from '../layout/layout-index.vue';
import ChildLayout from '../views/components/child-layout.vue';

/** @type {Array<import('vue-router').RouteRecordNormalized>} */
const layoutMap = [
  {
    // path: 'bingfeng-tech',
    // name: 'smartMaintenance',
    path: '/',
    name: 'index',
    component: () => import('@/views/smart-maintenance/smart-maintenance.vue'),
    meta: { title: '冷链智慧维保' },
    children: [
      {
        path: 'detail/:id/:scrollY2?',
        name: 'smartMaintenanceDetail',
        props: true,
        component: () => import('@/views/smart-maintenance/smart-maintenance-detail.vue'),
        meta: { title: '冷链智慧维保详情' },
        children: [],
      },
    ],
  },
  // {
  //   path: ' ',
  //   name: 'index',
  //   component: () => import('@/views/index/index.vue'),
  //   meta: { title: '首页', icon: '' },
  // },
  {
    path: 'receptionColdStorage',
    name: 'receptionColdStorage',
    component: () => import('@/views/reception-cold-storage/reception-cold-storage.vue'),
    meta: { title: '冷库招商前台' },
    children: [
      {
        path: 'detail/:id?',
        name: 'receptionColdStorageEdit',
        props: true,
        component: () => import('@/views/reception-cold-storage/reception-cold-storage-detail.vue'),
        meta: { title: '冷库招商详情', back: 'receptionColdStorage' },
      },
    ],
  },
  {
    path: 'receptionJoinUs',
    name: 'receptionJoinUs',
    component: () => import('@/views/reception-join-us/reception-join-us.vue'),
    meta: { title: '加入我们' },
    children: [],
  },
  {
    path: 'coldChain',
    name: 'coldChain',
    component: () => import('@/views/cold-chain/cold-chain.vue'),
    meta: { title: '冷链物流服务', phone: '4008057771', address: '物流服务电话' },
    children: [],
  },
  {
    path: 'operate',
    name: 'operate',
    component: () => import('@/views/operate/operate.vue'),
    meta: { title: '冷链资产运营' },
    children: [
      {
        path: 'detail/:id/:isIndex?',
        name: 'operateDetail',
        props: true,
        component: () => import('@/views/operate/operate-edit.vue'),
        meta: { title: '冷链资产运营详情' },
        children: [],
      },
      {
        path: 'list/:city',
        name: 'operateList',
        props: true,
        component: () => import('@/views/operate/operate-list.vue'),
        meta: { title: '冷链资产运营列表' },
        children: [
          {
            path: 'detail/:id',
            name: 'operateListDetail',
            props: true,
            component: () => import('@/views/operate/operate-list-detail.vue'),
            meta: { title: '冷链资产运营详情' },
            children: [],
          },
        ],
      },
    ],
  },
  {
    path: 'receptionClassicCases',
    name: 'receptionClassicCases',
    component: () => import('@/views/reception-classic-cases/reception-classic-cases.vue'),
    meta: { title: '经典案例' },
    children: [
      {
        path: 'detail/:id?',
        name: 'receptionClassicCasesDetail',
        props: true,
        component: () => import('@/views/reception-classic-cases/reception-classic-cases-detail.vue'),
        meta: { title: '经典案例详情' },
        children: [],
      },
    ],
  },
  {
    path: 'receptionNewsCenter',
    name: 'receptionNewsCenter',
    component: () => import('@/views/reception-news-center/reception-news-center.vue'),
    meta: { title: '新闻中心前台' },
    children: [
      {
        path: 'detail/:id?/:isIndex?',
        name: 'receptionNewsCenterDetail',
        props: true,
        component: () => import('@/views/reception-news-center/reception-news-center-detail.vue'),
        meta: { title: '新闻中心详情' },
        children: [],
      },
    ],
  },
  {
    path: 'receptionEnterSpikeDepth',
    name: 'receptionEnterSpikeDepth',
    component: () => import('@/views/reception-enter-spike-depth/reception-enter-spike-depth.vue'),
    meta: { title: '走进穗深前台' },
    children: [],
  },
  {
    path: 'design/:scrollY?',
    name: 'design',
    props: true,
    component: () => import('@/views/design/design.vue'),
    meta: { title: '冷链设计建造' },
    children: [
      {
        path: 'detail/:id/:scrollY2?',
        name: 'designDetail',
        props: true,
        component: () => import('@/views/design/desing-detail.vue'),
        meta: { title: '冷链设计建造详情' },
        children: [],
      },
    ],
  },
  {
    path: 'partner',
    name: 'partner',
    component: () => import('@/views/partner/partner.vue'),
    meta: { title: '战略伙伴' },
    children: [],
  },
  // 企业后台管理
  {
    path: 'enterprise',
    name: 'enterprise',
    component: ChildLayout,
    meta: { title: '企业后台' },
    children: [
      {
        path: 'homePageConfiguration',
        name: 'homePageConfiguration',
        meta: { title: '首页' },
        component: () => import('@view/enterprise/home-page-configuration/home-page-configuration.vue'),
        children: [],
      },
      {
        path: 'enterSpikeDepth',
        name: 'enterSpikeDepth',
        meta: { title: '走进穗深' },
        component: () => import('@view/enterprise/enter-spike-depth/enter-spike-depth.vue'),
        children: [],
      },
      {
        path: 'operateCom',
        name: 'operateCom',
        meta: { title: '冷链资产运营' },
        component: () => import('@view/enterprise/operate/operate.vue'),
        children: [
          {
            path: 'edit/:id?',
            name: 'operateComEdit',
            meta: { title: '冷链资产运营编辑', back: 'operateCom' },
            props: true,
            component: () => import('@view/enterprise/operate/operate-edit.vue'),
            children: [],
          },
        ],
      },
      {
        path: 'classicCases',
        name: 'classicCases',
        meta: { title: '冷链设计建造和智慧维保' },
        component: () => import('@view/enterprise/classic-cases/classic-cases.vue'),
        children: [
          {
            path: 'edit/:id?',
            name: 'classicCasesEdit',
            meta: { title: '冷链设计建造和智慧维保编辑', back: 'classicCases' },
            props: true,
            component: () => import('@view/enterprise/classic-cases/classic-cases-edit.vue'),
            children: [],
          },
        ],
      },
      {
        path: 'newsCenter',
        name: 'newsCenter',
        meta: { title: '新闻中心' },
        component: () => import('@view/enterprise/news-center/news-center.vue'),
        children: [
          {
            path: 'edit/:id?',
            name: 'newsCenterEdit',
            meta: { title: '新闻中心编辑', back: 'newsCenter' },
            props: true,
            component: () => import('@view/enterprise/news-center/news-center-edit.vue'),
            children: [],
          },
        ],
      },
      {
        path: 'joinUs',
        name: 'joinUs',
        meta: { title: '加入我们配置' },
        component: () => import('@view/enterprise/join-us/join-us.vue'),
        children: [
          {
            path: 'edit/:id?',
            name: 'joinUsEdit',
            meta: { title: '加入我们配置编辑', back: 'joinUs' },
            props: true,
            component: () => import('@view/enterprise/join-us/join-us-edit.vue'),
            children: [],
          },
        ],
      },
      {
        path: 'coldStorage',
        name: 'coldStorage',
        meta: { title: '冷库招商(已废弃)' },
        component: () => import('@view/enterprise/cold-storage/cold-storage.vue'),
        children: [
          {
            path: 'edit/:id?',
            name: 'coldStorageEdit',
            meta: { title: '冷库招商编辑', back: 'coldStorage' },
            props: true,
            component: () => import('@view/enterprise/cold-storage/cold-storage-edit.vue'),
            children: [],
          },
        ],
      },
    ],
  },
];

const routes = [
  {
    path: '/', name: 'layout', component: Layout, children: [...layoutMap],
  },
];

export {
  routes,
  layoutMap,
};
